<template>
  <b-container fluid>
    <b-breadcrumb class="mt-3">
      <b-breadcrumb-item :to="{ name: 'HistoryEvent' }">
        歷史評鑑列表
      </b-breadcrumb-item>
      <b-breadcrumb-item
        :to="{ name: 'EventDashboard', params: { eventID: eventID } }"
      >
        <b-icon v-if="event.type === 'remote'" icon="cloud"></b-icon>
        {{ event.name }}
        <b-badge v-if="event.status === 'ongoing'" variant="success">
          進行中
        </b-badge>
        <b-badge v-if="event.status === 'done'" variant="secondary">
          已結束
        </b-badge>
        <b-badge v-if="event.status === 'editing'" variant="info">
          編輯中
        </b-badge>
      </b-breadcrumb-item>
      <b-breadcrumb-item
        :to="{
          name: 'EventDashboard',
          params: { eventID: eventID },
          hash: `#RoundCard-${round.id}`
        }"
      >
        {{ round.name }}
        <b-badge v-if="round.status === 'ongoing'" variant="success">
          進行中
        </b-badge>
        <b-badge v-if="round.status === 'done'" variant="secondary">
          已結束
        </b-badge>
        <b-badge v-if="round.status === 'editing'" variant="info">
          編輯中
        </b-badge>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>評鑑儀錶板</b-breadcrumb-item>
    </b-breadcrumb>
    <b-row>
      <b-col>
        <h1>{{ round.name }} 評鑑狀態</h1>
      </b-col>
    </b-row>
    <b-row align-h="between" align-v="center">
      <b-col>
        <div class="d-flex justify-content-start align-items-center flex-wrap">
          <h4 class="mb-1 text-nowrap">豆號選擇：</h4>
          <b-btn
            @click="selectTarget = { code: 'Auto', id: -1 }"
            :variant="selectTarget.id === -1 ? 'info' : 'secondary'"
            class="m-1"
            pill
            v-b-tooltip.hover.topright
            title="只列出有扣分項"
          >
            Auto
          </b-btn>

          <template v-for="(m, index) in targets">
            <b-btn
              @click="selectTarget = m"
              :variant="selectTarget.id === m.id ? 'info' : 'secondary'"
              class="mx-1"
              pill
              :key="index"
            >
              {{ m.code }}
            </b-btn>
          </template>
        </div>
      </b-col>
      <b-col cols="5">
        <div class="d-flex justify-content-end align-items-center flex-wrap">
          <b-form-checkbox v-model="flagOfJudgeName" switch size="lg">
            <p class="text-nowrap mb-0 mr-3">
              <b v-if="flagOfJudgeName">顯示評審名字</b>
              <b v-else>顯示評審代號</b>
            </p>
          </b-form-checkbox>
          <label style="min-width:9rem">
            資料更新間隔(秒)
            <b-form-spinbutton
              v-model="pollTime"
              min="0"
              max="100"
              step="0.5"
              size="sm"
            ></b-form-spinbutton>
          </label>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-table striped responsive="xl" :fields="fields" :items="tableData">
          <template #cell(status)="data">
            <h5>
              <b-badge v-if="data.value === 'submitted'" variant="primary">
                已送繳成績
              </b-badge>
              <b-badge v-if="data.value === 'unsubmit'" variant="warning">
                未送繳成績
              </b-badge>
              <b-badge v-if="data.value === 'resubmit'" variant="danger">
                待重繳成績
              </b-badge>
              <b-badge v-if="data.value === 'ignore'" variant="secondary">
                被乎略的成績
              </b-badge>
            </h5>
          </template>

          <!-- 評審 豆號 -->
          <template #cell(judge_bean)="data">
            <div
              class="d-flex align-items-center justify-content-center text-truncate"
            >
              <p v-if="flagOfJudgeName" class="mb-0 mx-1">
                {{ data.item.user_name }}
              </p>
              <p v-else class="mb-0 mx-1">
                {{ data.item.rater_code }}
              </p>
              <b-badge variant="primary">
                {{ data.item.target_code }}
              </b-badge>
            </div>
          </template>

          <!-- 扣分 欄 -->
          <template #cell(defect)="data">
            <div
              class="d-flex align-items-center"
              v-if="
                data.item['taint_cups'] != null ||
                  data.item['fault_cups'] != null
              "
            >
              <div class="d-flex">
                <p class="mb-0">
                  {{
                    data.item["taint_cups"] * 2 + data.item["fault_cups"] * 4
                  }}
                  <b-badge
                    v-b-tooltip.hover
                    title="Taint Cups"
                    variant="danger"
                  >
                    {{ data.item["taint_cups"] || "0" }} </b-badge
                  >{{ " " }}
                  <b-badge
                    v-b-tooltip.hover
                    title="Fault Cups"
                    variant="danger"
                  >
                    {{ data.item["fault_cups"] || "0" }}
                  </b-badge>
                </p>
              </div>
            </div>
            <p class="mb-0" v-else>---</p>
          </template>

          <!-- 指令 欄 -->
          <template #cell(tool)="row">
            <b-btn @click="row.toggleDetails" pill>
              <b-icon icon="box-arrow-down"> </b-icon>
            </b-btn>
          </template>

          <!-- row 展開 -->
          <template #row-details="row">
            <div class="d-flex flex-column">
              <div class="d-flex align-items-center my-2">
                <strong style="min-width:3rem">正評:</strong>
                <div class="tagCard d-flex flex-wrap px-2 py-1">
                  <template
                    v-for="(tag, index) in scoreItems
                      .map(s => row.item[s + '_pos'])
                      .flat()"
                  >
                    <b-badge
                      v-if="tag != null"
                      class="m-1"
                      :key="index"
                      variant="info"
                    >
                      {{ tag }}
                    </b-badge>
                  </template>
                </div>
              </div>
              <div class="d-flex align-items-center my-2">
                <strong style="min-width:3rem">負評:</strong>
                <div class="tagCard d-flex flex-wrap px-2 py-1">
                  <template
                    v-for="(tag, index) in scoreItems
                      .map(s => row.item[s + '_neg'])
                      .flat()"
                  >
                    <b-badge
                      v-if="tag != null"
                      class="m-1"
                      :key="index"
                      variant="danger"
                    >
                      {{ tag }}
                    </b-badge>
                  </template>
                </div>
              </div>
            </div>
          </template>

          <!-- 資料 欄 -->
          <template #cell()="data">
            <p class="mb-0 mx-1">{{ data.value || "-" }}</p>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { API } from "../store/api";
export default {
  name: "EventStatus",
  props: {
    eventID: {
      type: Number,
      require: true
    },
    roundID: {
      type: Number,
      require: true
    }
  },
  computed: {},
  watch: {
    selectTarget(newVal) {
      if (newVal.id === -1) {
        this.dataUpdate();
      } else {
        this.dataUpdate(newVal.id);
      }
    }
  },
  mounted() {
    this.autoRefreshData();
  },
  methods: {
    async dataUpdate(target_id = undefined) {
      let data = await API.GetRound(this.eventID, this.roundID);
      this.event = data.event;
      this.round = data.round;
      this.targets = data.round.target_ids.map(id => {
        let target = data.round.targets.find(t => t.id === id);
        return target;
      });

      this.tableData = await API.GetRoundScores(this.roundID, target_id); //取得主資料

      this.tableData.forEach((cur, index, arr) => {
        this.$set(arr[index], "_showDetails", true);
        this.$set(arr[index], "_cellVariants", {
          defect: cur.taint_cups * 2 + cur.fault_cups * 4 > 0 ? "danger" : null,
          uniformity_score: cur.uniformity_score < 10 ? "danger" : null,
          cleancup_score: cur.cleancup_score < 10 ? "danger" : null,
          sweetness_score: cur.sweetness_score < 10 ? "danger" : null
        });
      });

      if (data.round.status === "done") {
        this.pollTime = 0;
      }
    },
    async autoRefreshData() {
      try {
        await this.dataUpdate(
          this.selectTarget.id === -1 ? undefined : this.selectTarget.id
        );
      } catch (error) {
        this.pollTime *= 2;
      }
      await new Promise(resolve => setTimeout(resolve, this.pollTime * 1000));
      if (this.pollTime !== 0) {
        this.autoRefreshData();
      }
    }
  },
  beforeDestroy() {
    this.pollTime = 0;
  },
  data() {
    return {
      event: {},
      round: {},
      targets: [],
      flagOfJudgeName: false,
      pollTime: 5,
      scoreItems: [
        "aroma",
        "flavor",
        "aftertaste",
        "acidity",
        "body",
        "uniformity",
        "cleancup",
        "sweetness",
        "balance",
        "overall"
      ],
      selectTarget: { code: "Auto", id: -1 },
      fields: [
        {
          key: "status",
          label: "分數狀態",
          sortable: true
        },
        {
          key: "judge_bean",
          label: "評審(豆子暗碼)",
          sortable: true
        },
        {
          key: "final",
          label: "總分",
          sortable: true
        },
        {
          key: "defect",
          label: "扣分",
          sortable: true
        },
        {
          key: "aroma_score",
          label: "香氣",
          sortable: true
        },
        {
          key: "flavor_score",
          label: "風味",
          sortable: true
        },
        {
          key: "aftertaste_score",
          label: "餘韻",
          sortable: true
        },
        {
          key: "acidity_score",
          label: "酸質",
          sortable: true
        },
        {
          key: "body_score",
          label: "醇度",
          sortable: true
        },
        {
          key: "uniformity_score",
          label: "一致性",
          sortable: true
        },
        {
          key: "cleancup_score",
          label: "乾淨度",
          sortable: true
        },
        {
          key: "sweetness_score",
          label: "甜感",
          sortable: true
        },
        {
          key: "balance_score",
          label: "平衡感",
          sortable: true
        },

        {
          key: "overall_score",
          label: "綜評",
          sortable: true
        }
        // {
        //   key: "tool",
        //   label: "指令",
        //   sortable: false
        // }
      ],
      tableData: []
    };
  }
};
</script>

<style lang="scss" scoped>
.tagCard {
  min-width: 10rem;
  min-height: 2rem;

  margin: 0rem 1rem;

  border-radius: 7px;
  background: #e6e6e6;
  box-shadow: 5px 5px 10px #d1d1d1, -5px -5px 10px #ffffff;
}
</style>
